<template>
  <div>
    <!-- 查看采集码数 -->
    <el-dialog title="图像采集码" :visible.sync="showCjm" center width="380px">
      <template v-if="cjmString">
        <div class="Cjmbox" ref="creditQrCodeShare">
          <div class="Cjminfo">
            <ul>
              <li>{{ CJMdata.name }}</li>
              <li>{{ CJMdata.idNumber }}</li>
              <li>{{ CJMdata.schoolName }}</li>
              <li>{{ CJMdata.cc }}</li>
            </ul>
          </div>
          <div class="CJMimagebox">
            <div id="qrcode" ref="qrCodeDiv"></div>
          </div>
        </div>
        <div style="width: 100%; text-align: center; cursor: pointer">
          <el-button type="success" @click="downloadCjm">下载采集码</el-button>
        </div>
      </template>
      <template v-else>
        <div class="CjmImg">
          <el-image :src="photoImg"> </el-image>
          <div style="width: 100%; text-align: center; cursor: pointer">
            <el-button type="success" @click="clickDown('img')"
              >下载采集码</el-button
            >
          </div>
        </div>
      </template>
    </el-dialog>
  </div>
</template>
<script>
import { formatPh, formatName, formatIdCard } from "@/utils/validate";
import QRCode from "qrcodejs2";
import html2canvas from "html2canvas";
import { getToken } from "@/utils/auth";
import axios from "axios";
export default {
  name: "qrCodeBox",

  data() {
    return {
      showCjm: false,
      CJMdata: {},
      cjmString: null,
      photoImg: "../temp/none_img.jpg",
    };
  },
  methods: {
    initDatas(cjmStr, CJMimage, data) {
      // console.log(data);
      this.CJMdata = {};
      this.photoImg = "../temp/none_img.jpg";
      this.cjmString = null;
      this.CJMdata = data;
      if (cjmStr) {
        this.cjmString = cjmStr;
        this.showCjm = true;
        setTimeout(() => {
          document.getElementById("qrcode").innerHTML = "";
          this.creatQrCode();
        }, 100);
      } else {
        this.getPhoto(CJMimage);
      }
    },
    // 生成采集码
    creatQrCode() {
      if (this.$refs.qrCodeDiv != undefined) {
        var qrcode = new QRCode(this.$refs.qrCodeDiv, {
          text: this.cjmString, // 需要转换为二维码的内容
          width: 230,
          height: 230,
          colorDark: "#000000",
          colorLight: "#ffffff",
          correctLevel: QRCode.CorrectLevel.Q,
        });
        qrcode._el.title = ""; //隐藏title
      }
    },
    //下载采集码
    downloadCjm() {
      this.$confirm("是否下载该图像采集码?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          html2canvas(this.$refs.creditQrCodeShare, {
            backgroundColor: null, //画出来的图片有白色的边框,不要可设置背景为透明色（null）
            useCORS: true, //支持图片跨域
            scale: 1, //设置放大的倍数
          }).then((canvas) => {
            this.clickDown("code", canvas);
          });
        })
        .catch(() => {});
    },
    clickDown(mod, canvas) {
      let a = document.createElement("a");
      let imgType = "";
      if (mod == "code") {
        a.href = canvas.toDataURL("image/png");
      } else {
        a.href = this.photoImg;
        imgType = ".png";
      }
      let str =
        this.CJMdata.name +
        "-" +
        this.CJMdata.studentCode +
        "-" +
        this.CJMdata.schoolName +
        "-" +
        this.CJMdata.schoolCode +
        imgType;
      a.download = str;
      a.click();
      this.$message({
        message: "采集码正在进行下载保存",
        type: "success",
      });
    },
    getPhoto(fileid) {
      const url =
        //window._config["baseUrl"] +
        "/api/csias/file/download/" +
        fileid +
        "?timestamp=" +
        new Date().getTime();
      axios({
        method: "GET",
        url: url,
        headers: {
          Authorization: "Bearer " + getToken(),
        },
        responseType: "blob",
        timeout: 180000,
      }).then(
        (response) => {
          if (response.status === 200 && response.data) {
            const imgContent = response.data;
            this.photoImg = window.URL.createObjectURL(imgContent);
            this.showCjm = true;
          }
        },
        (err) => {
          this.$notify.error({
            title: "错误",
            message: "照片加载失败，未查询到照片信息",
          });
        }
      );
    },
    // 姓名、手机号脱敏
    execDecrypt(str, mod) {
      if (!str) {
        return str;
      } else if (mod == "name") {
        return formatName(str);
      }
      if (mod == "ph") {
        return formatPh(str);
      }
      if (mod == "Idcard") {
        return formatIdCard(str);
      }
    },
  },
};
</script>

<style scoped>
ul {
  padding: 0px 0px 0px 10px;
  margin: 0px;
}
li {
  list-style: none;
  line-height: 37px;
}
.Cjmbox {
  background: url(../assets/img/Cjmbg.png) no-repeat center;
  width: 350px;
  height: 554px;
  color: #333;
}
.CjmImg {
  width: 350px;
  margin: 0 auto;
  text-align: center;
}

.Cjminfo {
  position: absolute;
  left: 100px;
  top: 129px;
  color: #000;
  font-family: 思源黑体;
}
.Cjminfo span {
  letter-spacing: -2px;
}
.CJMimagebox {
  left: 75px;
  top: 291px;
  position: absolute;
}
</style>
